<template>
  <div>
    <div v-if="gameId && playerId" class="game-panel">
      <div v-if="completed == false && !started" class="intro">
        <p>
          Hallo {{ playerName }},<br />
          es warten {{progressOpenCount(playerId)}} Fragen auf dich!<br />
          Bist du bereit?
        </p>

        <p>Antworte bitte stets aus deiner Sicht!</p>

        <b-button size="lg" @click="selectNext" variant="light">
          Los geht's!
        </b-button>

        <router-link class="btn btn btn-light btn-shuffle" :to="{ name: 'auswertung', params: { id: gameId }}">Auswertung</router-link>
      </div>

      <div v-if="completed == true" class="intro">
          <p>
            Hey {{ playerName }},<br />
            du hast es vorerst geschafft!<br/>Es sind keine Fragen mehr übrig!
          </p>
          <router-link class="btn btn-lg btn-light" :to="{ name: 'auswertung', params: { id: gameId }}">
            Auswertung
          </router-link>
      </div>

      <div v-if="started && activeQuestion">
        <b-progress
          :value="progressPlayer(1)"
          variant="warning"
          class="progress-1"
        ></b-progress>
        <b-progress
          :value="progressPlayer(2)"
          variant="info"
          class="progress-2"
        ></b-progress>
        <b-progress
          :value="matchProgress"
          variant="success"
          class="progress-match"
        ></b-progress>

        <span class="question-number">{{ activeQuestion.id }}</span>

        <button class="btn btn btn-light btn-shuffle" v-if="!showSpinner && progressOpenCount(playerId) > 1" @click="shuffleAndNext">Ich überlege noch...</button>

        <b-spinner
          class="question-spinner"
          small
          variant="light"
          v-if="showSpinner"
        ></b-spinner>
        <b-alert v-model="activeMatch" variant="success"
          >{{ otherPlayerName }} hat genauso geantwortet!</b-alert
        >
        <b-alert variant="dark" v-model="activeUnanswered"
          >{{ otherPlayerName }} hat dies noch nicht beantwortet.<br />Du legst
          vor.</b-alert
        >

        <div class="question d-grid gap-2">
          <b-button
            class="main-button"
            block
            size="lg"
            :style="{ transform: button2Transform }"
            :disabled="
              (playerId == 1 && activeQuestion.player1 != null) ||
              (playerId == 2 && activeQuestion.player2 != null)
            "
            :active="false"
            :variant="buttonVariant(0)"
            @click="selectOption(0)"
          >
            {{ activeQuestion.options[0] }}
          </b-button>
          oder
          <b-button
            class="main-button"
            block
            size="lg"
            :style="{ transform: button1Transform }"
            :active="false"
            :disabled="
              (playerId == 1 && activeQuestion.player1 != null) ||
              (playerId == 2 && activeQuestion.player2 != null)
            "
            :variant="buttonVariant(1)"
            @click="selectOption(1)"
          >
            {{ activeQuestion.options[1] }}
          </b-button>
        </div>
      </div>
    </div>

    <div v-else class="game-panel">Irgendwas ist leider schief gegangen.</div>
  </div>
</template>

<script>
import service from "../service";

//import {useToast} from 'bootstrap-vue-3'

export default {
  name: "QuestionsView",
  props: ['routeGameId', 'routePlayerId'],
  data() {
    return {
      playerId: null,
      playerName: null,
      otherPlayerName: null,
      gameId: null,
      gameName: null,
      questions: [],

      activeQuestion: null,
      activeMatch: true,
      activeUnanswered: true,
      button1Transform: null,
      button2Transform: null,

      started: false,
      completed: false,
      showSpinner: false,
    };
  },
  computed: {
    progressPlayer() {
      let s = this;
      return (player) => {
        if (s.questions.length) {
          let answered = 0;
          s.questions.forEach((q) => {
            answered +=
              (player == 1 && q.player1 != null) ||
              (player == 2 && q.player2 != null)
                ? 1
                : 0;
          });
          return (answered / s.questions.length) * 100;
        }
        return 0;
      };
    },
    progressOpenCount() {
      let s = this;
      return (player) => {
        if (s.questions.length) {
          let open = 0;
          s.questions.forEach((q) => {
            open +=
              (player == 1 && q.player1 == null) ||
              (player == 2 && q.player2 == null)
                ? 1
                : 0;
          });
          return open;
        }
        return 0;
      };
    },
    matchProgress() {
      if (this.questions.length) {
        let matches = 0;
        this.questions.forEach((q) => {
          matches += q.player1 != null && q.player1 == q.player2 ? 1 : 0;
        });
        return (matches / this.questions.length) * 100;
      }
      return 0;
    },
    hasNext() {
      let self = this;
      let res =  this.questions.find((q) => self.playerId == 1 ? q.player1 == null : q.player2 == null);
      console.log('unanswered', res, );
      return null != res;
    },
    buttonVariant() {
      let s = this;
      return (choice) => {
        if (
          (s.playerId == 1 && s.activeQuestion?.player1 == null) ||
          (s.playerId == 2 && s.activeQuestion?.player2 == null)
        ) {
          return "secondary";
        }
        if (s.activeMatch && !s.inverted && s.activeQuestion?.player1 == choice) {
          return "success";
        }
        if (s.activeUnanswered &&
          (s.activeQuestion?.player1 == choice ||
            s.activeQuestion?.player2 == choice)
        ) {
          return s.playerId == 1 ? "warning" : "info";
        }
        if (!s.activeUnanswered && !s.activeMatch ) {
          if (s.playerId == 1) {
              return (s.activeQuestion?.player1 == choice) ? "warning" : "info";
          }
          if (s.playerId == 2) {
            return (s.activeQuestion?.player2 == choice) ? "info" : "warning";
          }
        }
       
        return "secondary";
      };
    },
  },
  async beforeRouteEnter(to, from, next) {
    let gameId = Number.parseInt(to.params.id, 10) || 0;
    let playerId = Number.parseInt(to.params.player, 10) || 0;
    let gameData = {};
    try {
      gameData = await service.getQuestions(gameId, playerId);
      console.log("gameData", gameData);
    } catch (e) {
      console.log("failed to load questions");
    }
    next((vm) => {
      vm.playerId = playerId;
      vm.gameId = gameId;
      vm.questions = (gameData.questions || []).sort(() => 0.5 - Math.random());
      vm.gameName = gameData.name || null;
      vm.playerName = gameData.currentPlayer || "du";
      vm.otherPlayerName = gameData.otherPlayer || "Dein Gegenüber";

      vm.$nextTick(function() {
        if (!vm.hasNext) {
          vm.finalize();
        }
      });
    });
  },
  methods: {
    getRandomRotation() {
      let rotate = (Math.random() - 0.5) * 2;
      return `rotate(${rotate}deg)`;
    },
    selectNext() {
      this.showSpinner = false;
      this.activeMatch = false;
      this.activeUnanswered = false;

      let self = this;

      let q = this.questions.find((q) =>
        self.playerId == 1 ? q.player1 == null : q.player2 == null
      );
      if (q == null) {
        this.finalize();
      } else {
        console.log("selected question", q);
        this.activeQuestion = q;
        this.activeUnanswered =
          (this.playerId == 1 && q.player2 == null) ||
          (this.playerId == 2 && q.player1 == null);

        this.button1Transform = this.getRandomRotation();
        this.button2Transform = this.getRandomRotation();
        this.started = true;
      }
    },
    finalize() {
      console.log("finalized!");
      this.completed = true;
    },
    shuffleAndNext() {
      this.questions = (this.questions || []).sort(() => 0.5 - Math.random());
      this.selectNext();
    },
    async selectOption(choice) {
      var self = this;
      if (this.playerId == 1) {
        if (this.activeQuestion.player1 == choice) return;
        this.activeQuestion.player1 = choice;
      }
      if (this.playerId == 2) {
        if (this.activeQuestion.player2 == choice) return;
        this.activeQuestion.player2 = choice;
      }

      this.showSpinner = true;
      await service.postAnswer(
        this.gameId,
        this.activeQuestion.id,
        this.playerId,
        choice
      );

      if (
        this.activeQuestion.player2 != null &&
        this.activeQuestion.player2 == this.activeQuestion.player1
      ) {
        this.activeMatch = true;
      }

      console.log("updated question", this.activeQuestion);

      setTimeout(function () {
        self.activeQuestion = null;
        self.selectNext();
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.progress {
  position: fixed;
  left: 0;
  width: 100vw;
  border: none;
  background-color: transparent !important;
  overflow: hidden;

  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
  &-1 {
    bottom: 1px;
    height: 3px;
  }
  &-2 {
    bottom: 5px;
    height: 3px;
  }
  &-match {
    bottom: 9px;
    height: 3px;
  }
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 16px;
  text-align: center;
  border-radius: 0 !important;
}

.game-panel {
    width: 85%;
    font-size: 2em;
    height: 100vh;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
}

.intro {
  text-align: center;
}

.question {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.question-number {
  position: fixed;
  font-size: 16px;
  bottom: 15px;
  left: 12px;
}

.btn-lg {
  font-size: 2rem;
}

.main-button {
  width: 80vw;
  transition: all 0.5s ease-in-out;
  min-height: 22vh;
  border-radius: 5vw;

    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.6);

  &:first-of-type {
    transform: rotate(1deg);
  }
  &:last-of-type {
    transform: rotate(-1deg);
  }
}

.question-spinner, .btn-shuffle {
  position: fixed;
  right: 15px;
  bottom: 15px;
}
</style>