import axios from 'axios';

const baseUrl = 'https://oder.svkro.de/api/'

export default {
    async create(name, player1, player2) {
        const res = await fetch(baseUrl + 'create', {
            method: 'POST',
            body: {
                name: name,
                player1: player1,
                player2: player2
            }
        })
        await res.json();
        
        /**
         * Successful.
         */

        return {
            gameId: 'test',
        };


    },

    async postAnswer(gameId, questionId, playerId, choice) {
        return await axios.postForm(
            baseUrl + 'answer',
            {
                'game': gameId,
                'question': questionId,
                'player': playerId,
                'answer': choice
            },
            {headers: {'Content-Type': 'multipart/form-data'}}
          )
    },

    async getQuestions(gameId, playerId) {

        const res = await axios({
            method: 'get',
            url: baseUrl + 'game?g=' + gameId + '&player=' + playerId
          })
          

        const response = await res.data;

        let game = response.game;
        let questions = response.questions;
        let answers = response.answers;

        questions.forEach(q => {           
            let answerPlayer1 = answers.find(a => a.question === q.id && a.player === 1);
            let answerPlayer2 = answers.find(a => a.question === q.id && a.player === 2);

            q.options = [q.choice1, q.choice2];
            delete q.choice1;
            delete q.choice2;
            q.player1 = answerPlayer1 != null ? answerPlayer1.answer : null;
            q.player2 = answerPlayer2 != null ? answerPlayer2.answer : null;
            q.isCompleted = answerPlayer1 != null && answerPlayer2 != null;
            q.isMatch = q.isCompleted && q.player1 == q.player2 && !q.inverted;
            q.isInvertedMatch = q.isCompleted && q.player1 != q.player2 && q.inverted;
        });

        return {
            name: game.name || 'Oder?',
            player1: game.player1,
            player2: game.player2,
            currentPlayer: playerId == 1 ? game.player1 : game.player2,
            otherPlayer: playerId == 1 ? game.player2 : game.player1,
            questions: questions
        }
    }
}