<template>
  <div class="game-panel">
    <h1>Oder?</h1>
  </div>
</template>

<script>
export default {
  name: "StartView",
};
</script>

<style lang="scss" scoped>
h1 { color: #fff; font-size: 3rem; }
.game-panel {
    font-size: 2em;
    height: 100vh;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin: 0 auto;
}
</style>