<template>
  <div>
    <div class="xcard" v-if="initState">
      <h1>Neue Runde</h1>

      <b-form @submit="onSubmit">
        <b-form-group id="input-group-1" label="Name" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="name"
            placeholder="Name der Runde"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Spieler 1" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="player1"
            placeholder="Spieler 1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Spieler 2" label-for="input-3">
          <b-form-input
            id="input-3"
            v-model="player2"
            placeholder="Spieler 2"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="secondary">Los gehts</b-button>
      </b-form>
    </div>

    <div v-if="readyState" class="xcard">
        <p>Runde mit dem Namen "{{ name }}" angelegt!</p>
        <b-form>
          <b-form-group
            id="link1g"
            label="Link für Spieler 1"
            label-for="link1"
          >
            <b-form-input id="link1" v-model="player1Link"></b-form-input>
          </b-form-group>

          <b-form-group
            id="link2g"
            label="Link für Spieler 2"
            label-for="link2"
          >
            <b-form-input id="link2" v-model="player2Link"></b-form-input>
          </b-form-group>
        </b-form>

<p>
        <ShareNetwork
          network="telegram"
          :url="player2Link"
          :title="name" class="btn btn-block btn-secondary">
          <i class="fab fah fa-telegram"></i>&nbsp;<span>Telegram</span>
        </ShareNetwork>
</p><p>
        <ShareNetwork
          network="whatsapp"
          :url="player2Link"
          :title="name" class="btn btn-block btn-secondary">
          <i class="fab fah  fa-whatsapp"></i>&nbsp;<span>WhatsApp</span>
        </ShareNetwork>
        </p>
<p>
        <router-link class="btn btn-block btn-success" :to="{ name: 'fragen', params: { player: 1, id: gameId }}">Fragen selber beantworten</router-link>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateView",
  data() {
    return {
      initState: true,
      name: "",
      player1: "",
      player2: "",

      readyState: false,
      player1Link: null,
      player2Link: null,
      gameId: null,
    };
  },
  methods: {
    onSubmit() {
      if (
        this.name != null &&
        this.name.length &&
        this.player1 != null &&
        this.player1.length &&
        this.player1 != null &&
        this.player1.length
      ) {
        this.initState = false;
        this.readyState = true;
        
        this.gameId = 1;
        this.player1Link = window.location.href.replace('/neu', '/fragen/' + this.gameId + '/1')
        this.player2Link = window.location.href.replace('/neu', '/fragen/' + this.gameId + '/2')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .xcard {
        font-family: 'Raleway';
        width: 80vw;
    }

    h1 {
        color: #fff;
        font-family: 'Indie Flower'
    }

.btn-block { width: 100%;}
    
</style>