<template>
  <div class="result-view">
    <div v-if="gameId" class="game-panel">
      <h1>Auswertung</h1>
      <div class="alert alert-success" v-if="progressPlayer(1) === 100 && progressPlayer(2) === 100">
        Es wurden alle {{ questions.length }} Fragen vollständig beantwortet!
      </div>
      <div class="alert alert-warning" v-if="progressPlayer(1) !== 100 || progressPlayer(2) !== 100">
        <p>Es wird noch auf Antworten gewartet!</p>
        <div class="row mb-3">
          <div class="col text-center">
            <strong class="badge badge-lg bg-warning rounded-pill ">{{ player1Name }}</strong>
            <br />
            {{ answeredQuestionsPlayer(1) }}/{{ questions.length }} ({{ Math.floor(progressPlayer(1)) }}%)<br />
            <router-link v-if="progressPlayer(1) != 100" class="btn btn-sm btn-light border-warning"
              :to="{ name: 'fragen', params: { id: gameId, player: 1 } }">Antworten</router-link>
          </div>
          <div class="col text-center">
            <strong class="badge badge-lg bg-info rounded-pill">{{ player2Name }}</strong><br />
            {{ answeredQuestionsPlayer(2) }}/{{ questions.length }} ({{ Math.floor(progressPlayer(2)) }}%)<br />
            <router-link v-if="progressPlayer(2) != 100" class="btn btn-sm btn-light border-info"
              :to="{ name: 'fragen', params: { id: gameId, player: 2 } }">Antworten</router-link>
          </div>
        </div>
        <p>Du kannst später einfach nochmal vorbeischauen, vielleicht ist das
          Ergebnis dann ja vollständig.</p>
      </div>


      <div class="text-center mb-4 mt-2">
        <h3>Übereinstimmung?</h3>
        <b-progress :value="totalMatchedProgress">
          <b-progress-bar :value="matchedProgress" variant="success" />
          <b-progress-bar :value="invertedMatchedProgress" variant="success" striped />
          <b-progress-bar :value="invertedUnmatchedProgress" variant="secondary" striped />
          <b-progress-bar :value="unmatchedProgress" variant="secondary" />
          <b-progress-bar :value="openProgress" variant="light" striped />
        </b-progress>
        <h2 class="mb-2 mt-2">{{ Math.round(totalMatchedProgress) }}%</h2>
      </div>

      <b-accordion class="result-accordion">
        <b-accordion-item :title="matchedQuestions.length + ' Übereinstimmungen'" v-if="matchedQuestions.length > 0">
          <div class="question-list matched">
            <ResultEntry v-for="question in matchedQuestions" :key="question.id" :question="question"
              :player1Name="player1Name" :player2Name="player2Name" />
          </div>
        </b-accordion-item>
        <b-accordion-item
          :title="invertedMatchedQuestions.length + (invertedMatchedQuestions.length == 1 ? ' Ergänzung' : ' Ergänzungen')"
          v-if="invertedMatchedQuestions.length > 0">
          <div class="question-list matched_inverted">
            <ResultEntry v-for="question in invertedMatchedQuestions" :key="question.id" :question="question"
              :player1Name="player1Name" :player2Name="player2Name" />
          </div>
        </b-accordion-item>

        <b-accordion-item
          :title="invertedUnmatchedQuestions.length + (invertedUnmatchedQuestions.length == 1 ? ' möglicher Konflikt' : ' mögliche Konflikte')"
          v-if="invertedUnmatchedQuestions.length > 0">
          <div class="question-list unmatched">
            <ResultEntry v-for="question in invertedUnmatchedQuestions" :key="question.id" :question="question"
              :player1Name="player1Name" :player2Name="player2Name" />
          </div>
        </b-accordion-item>
        <b-accordion-item :title="unmatchedQuestions.length + ' Differenzen'" v-if="unmatchedQuestions.length > 0">
          <div class="question-list unmatched">
            <ResultEntry v-for="question in unmatchedQuestions" :key="question.id" :question="question"
              :player1Name="player1Name" :player2Name="player2Name" />
          </div>
        </b-accordion-item>
        <b-accordion-item :title="openQuestions.length + ' Unbeantwortet'" v-if="openQuestions.length > 0">
          <div class="question-list open">
            <ResultEntry v-for="question in openQuestions" :key="question.id" :question="question"
              :player1Name="player1Name" :player2Name="player2Name" />
          </div>
        </b-accordion-item>
      </b-accordion>



    </div>
  </div>
</template>

<script>
import service from "../service";
import ResultEntry from "./ResultEntry.vue";

export default {
  name: "ResultView",
  data() {
    return {
      player1Name: null,
      player2Name: null,
      gameId: null,
      gameName: null,
      questions: [],
    };
  },
  computed: {
    matchedQuestions() {
      return this.questions.filter(q => q.isCompleted && q.isMatch && !q.inverted);
    },
    invertedMatchedQuestions() {
      return this.questions.filter(q => q.isCompleted && q.isInvertedMatch && q.inverted);
    },
    unmatchedQuestions() {
      return this.questions.filter(q => q.isCompleted && !q.isMatch && !q.inverted);
    },
    invertedUnmatchedQuestions() {
      return this.questions.filter(q => q.isCompleted && !q.isInvertedMatch && q.inverted);
    },
    openQuestions() {
      return this.questions.filter(q => !q.isCompleted);
    },
    progressPlayer() {
      let s = this;
      return (player) => {
        if (s.questions.length) {
          return (this.answeredQuestionsPlayer(player) / s.questions.length) * 100;
        }
        return 0;
      };
    },
    answeredQuestionsPlayer() {
      let s = this;
      return (player) => {
        if (s.questions.length) {
          let answered = 0;
          s.questions.forEach((q) => {
            answered +=
              (player == 1 && q.player1 != null) ||
                (player == 2 && q.player2 != null)
                ? 1
                : 0;
          });
          return answered
        }
        return 0;
      };
    },
    matchedProgress() {
      if (this.questions.length) {
        return (this.matchedQuestions.length / this.questions.length) * 100;
      }
      return 0;
    },
    invertedMatchedProgress() {
      if (this.questions.length) {
        return (this.invertedMatchedQuestions.length / this.questions.length) * 100;
      }
      return 0;
    },
    totalMatchedProgress() {
      if (this.questions.length) {
        return ((this.invertedMatchedQuestions.length + this.matchedQuestions.length) / this.questions.length) * 100;
      }
      return 0;
    },
    unmatchedProgress() {
      if (this.questions.length) {
        return (this.unmatchedQuestions.length / this.questions.length) * 100;
      }
      return 0;
    },
    invertedUnmatchedProgress() {
      if (this.questions.length) {
        return (this.invertedUnmatchedQuestions.length / this.questions.length) * 100;
      }
      return 0;
    },
    openProgress() {
      if (this.questions.length) {
        return (this.openQuestions.length / this.questions.length) * 100;
      }
      return 0;
    },
  },
  async beforeRouteEnter(to, from, next) {
    let gameId = Number.parseInt(to.params.id, 10) || 0;
    let gameData = {};
    try {
      gameData = await service.getQuestions(gameId, 0);
      console.log("gameData", gameData);
    }
    catch (e) {
      console.log("failed to load questions");
    }
    next((vm) => {
      vm.gameId = gameId;
      vm.questions = gameData.questions || [];
      vm.gameName = gameData.name || null;
      vm.player1Name = gameData.player1 || "Spieler 1";
      vm.player2Name = gameData.player2 || "Spieler 2";
    });
  },
  methods: {},
  components: { ResultEntry }
};
</script>

<style lang="scss">
.result-view {

  h1,
  h2,
  h3 {
    color: #fff;
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem
  }

  h3 {
    font-size: 1.25rem
  }

  .game-panel {
    padding: 30px 10px;
    font-size: 1.25rem;
  }

  .badge-lg {
    font-size: 1.1em;
  }

  .row {
    margin-bottom: 3rem;
  }

  .card-body {
    font-size: 1rem;
  }

  .card {
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .col-6.option-1 {
    padding-right: 3px;
    transform: rotate(-.25deg);
  }
  .col-6.option-2 {
    padding-left: 3px;
    transform: rotate(.25deg);
  }
}
</style>