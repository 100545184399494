<template>
  <b-container class="main">
    <router-view>
    </router-view>
  </b-container>
</template>

<script>
export default {
  name: "App",
  data() {
    return { loading: false };
  },
  components: {},
};
</script>

<style lang="scss" scoped>
  .main {
    width: 100vw;
    display: flex;
    height: 100vh;
    overflow-x: hidden;

    position: relative;
    z-index: 4;

    & > div {
          width: 100%;
    }
  }
</style>
