<template>
    <div class="row justify-content-center align-items-center" :class="{
        'match': question.isMatch,
        'inverted-match': question.isInvertedMatch,
        'completed': question.isCompleted,
        'waiting': !question.isCompleted
    }">
        <div class="col-6 option-1 align-self-stretch option">
            <div class="card text-white  answer" :class="{
                'bg-success text-white ': (question.isMatch && !question.inverted && question.player1 == 0) || (question.inverted && question.isInvertedMatch),
                'bg-danger text-white ': (question.isCompleted && question.inverted && !question.isInvertedMatch && question.player1 == 0),
                'bg-secondary text-white ': !question.isCompleted || !question.isMatch || (question.isMatch && !question.inverted && question.player1 != 0)
            }">
                <div class="card-body  text-center p-2 pb-3">
                    <span v-if="question.isCompleted">{{ question.options[0] }}</span>
                    <span v-else>???</span>
                </div>
                <div class="position-relative">
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="!question.inverted && question.isMatch && question.player1 == 0">{{ player1Name }} & {{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-danger"
                        v-if="question.inverted && !question.isInvertedMatch && question.player1 == 0">{{ player1Name }} & {{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-warning"
                        v-if="!question.inverted && !question.isMatch && question.player1 == 0">{{ player1Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="question.inverted && question.isInvertedMatch && question.player1 == 0">{{ player1Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-info"
                        v-if="!question.inverted && !question.isMatch && question.player2 == 0">{{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="question.inverted && question.isInvertedMatch && question.player2 == 0">{{ player2Name }}</span>
                </div>
            </div>
        </div>

        <div class="col-6 option-2 align-self-stretch option">
            <div class="card answer" :class="{
                'bg-success text-white ': (question.isMatch && !question.inverted && question.player1 == 1) || (question.inverted && question.isInvertedMatch),
                'bg-danger text-white ': (question.isCompleted && question.inverted && !question.isInvertedMatch && question.player1 == 1),
                'bg-secondary text-white ': !question.isCompleted || !question.isMatch || (question.isMatch && question.player1 != 1)
            }">
                <div class="card-body text-center p-2 pb-3">
                    <span v-if="question.isCompleted">{{ question.options[1] }}</span>
                    <span v-else>???</span>
                </div>
                <div class="position-relative">
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="!question.inverted && question.isMatch && question.player1 == 1">{{ player1Name }} & {{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-danger"
                        v-if="question.inverted && !question.isInvertedMatch && question.player1 == 1">{{ player1Name }} & {{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-warning"
                        v-if="!question.inverted && !question.isMatch && question.player1 == 1">{{ player1Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="question.inverted && question.isInvertedMatch && question.player1 == 1">{{ player1Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-info"
                        v-if="!question.inverted && !question.isMatch && question.player2 == 1">{{ player2Name }}</span>
                    <span
                        class="position-absolute top-100 start-50 translate-middle rounded-pill badge badge-sm bg-success"
                        v-if="question.inverted && question.isInvertedMatch && question.player2 == 1">{{ player2Name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResultEntry',
    props: ['question', 'player1Name', 'player2Name']
}
</script>