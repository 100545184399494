
import { createRouter, createWebHashHistory } from "vue-router"
import StartView from './views/StartView.vue'
import CreateView from './views/CreateView.vue'
import QuestionsView from './views/QuestionsView.vue'
import ResultView from './views/ResultView.vue'

const routeInfos = [
    {
        path : "/",
        component : StartView
    },
    {
        path : "/neu",
        component : CreateView
    },
    {
        path : "/fragen/:id/:player",
        name: 'fragen',
        component : QuestionsView,
        props: ({params}) => ({
            id: Number.parseInt(params.id, 10) || 0,
            player: Number.parseInt(params.player, 10) || 0
        })
    },
    {
        path : "/auswertung/:id",
        name: 'auswertung',
        component : ResultView
    }
]

const router = createRouter({
    history : createWebHashHistory(),
    base: '/', 
    routes : routeInfos
})


export default router;