import '@/scss/_style.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"

import BootstrapVue3 from 'bootstrap-vue-3'
import VueSocialSharing from 'vue-social-sharing';


import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();


var app = createApp(App)

router.beforeEach((to, from, next) => {
    app.loading = true
      next()
  })
  
  router.afterEach(() => {
    setTimeout(() => app.loading = false, 1500) // timeout for demo purposes
  })



import {BToastPlugin} from 'bootstrap-vue-3'
app.use(BToastPlugin)

app.component("font-awesome-icon", FontAwesomeIcon)
app.use(BootstrapVue3)
app.use(router)
app.use(VueSocialSharing)

app.mount('#app')
